html,
body {
    padding: 0;
    margin: 0;
    direction: rtl;
    font-family: 'IranSans';
}

@font-face {
    font-family: 'IranSans';
    src: url('../font/iransans/IRANSansWeb.eot');
    src: url('../font/iransans/IRANSansWeb.eot?#iefix') format('embedded-opentype'),
    url('../font/iransans/IRANSansWeb.woff2') format('woff2'),
    url('../font/iransans/IRANSansWeb.woff') format('woff'),
    url('../font/iransans/IRANSansWeb.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


:root {
    --main-color: #f55;
    --gray-color: #7c7c7c;
    --light-gray-color: #f0f0f0;
}

a, a:link, a:hover {
    text-decoration: none;
    color: var(--main-color);
}

/* Scrollbar */

.custom-scroll::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}

.custom-scroll::-webkit-scrollbar-track {
    background: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb,
.custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #002140;
    border-radius: 3px;
}


/* Colors */

.gray-color {
    color: var(--gray-color);
}

/* Bootstrap */

.list-unstyled {
    list-style: unset;
}

.position-relative {
    position: relative;
}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.align-items-center {
    align-items: center;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.h-100-vh {
    height: 100vh;
}

.w-50 {
    width: 50%;
}

.m-auto {
    margin: auto;
}

.letter-space {
    letter-spacing: 10px;
}

.dir-ltr {
    direction: ltr;
}

.text-center {
    text-align: center;
}

/* Ant Design */

.auth-tabs .ant-tabs-tab-btn {
    font-size: 16px;
}

.ant-layout {
    background: #f7f7f7!important;
}

/* Fonts */

.font-size-11 {
    font-size: 11px;
}

.font-size-14 {
    font-size: 14px;
}

/* Common */

.SpecialAdsFast {
    color: white;
    background: #ff4444;
    display: inline-block;
    font-size: 11px;
    margin-right: 5px;
    padding: 3px 5px;
    border-radius: 3px;
}

.SpecialAds {
    color: #ff4444;
    border: 1px solid #ff4444;
    display: inline-block;
    font-size: 11px;
    padding: 0 5px 0 5px;
    margin: 0 5px 0 0;
    border-radius: 3px;
}

.LinkedAds {
    color: #878787;
    border: 1px solid #878787;
    display: inline-block;
    font-size: 11px;
    padding: 0 5px 0 5px;
    margin: 10px 5px 0 0;
    border-radius: 3px;
}

#maps {
    height: 393px;
    border-radius: 15px;
}

.ads-textarea {
    height: 100px!important;
}

.avatar.active {
    border: 10px solid var(--main-color);
    transition: .8s all ease;
}

@media all and (max-width: 767px){
    .ant-layout-header {
        padding: 0 15px;
    }
}