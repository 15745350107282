.SellerLogo {
  position: relative;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SellerLogo::before {
  content: "SELLER";
  position: absolute;
  background: var(--main-color);
  color: #fff;
  font-size: 9px;
  font-style: italic;
  padding: 3px 5px;
  border-radius: 4px;
  top: 15px;
  font-weight: 700;
  letter-spacing: 1px;
  left: -55px;
  height: auto;
  line-height: normal;
  direction: ltr;
}